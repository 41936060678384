@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300&family=Prata&display=swap');

:root {
    --COLOR-1: rgba(106, 4, 29, 0.7);
    --COLOR-2: #DEE2E6;
    --COLOR-3: #CBA328;
    --COLOR-4: #343A40;
    --COLOR-5: #212529;
    --COLOR-6: #495057;
    --COLOR-7: #6C757D;

    --DEFAULT-SHADOW:
        0 1.2px 3.7px rgba(0, 0, 0, 0.028),
        0 2.8px 8.9px rgba(0, 0, 0, 0.04),
        0 5.3px 16.8px rgba(0, 0, 0, 0.05),
        0 9.4px 29.9px rgba(0, 0, 0, 0.06),
        0 17.5px 56px rgba(0, 0, 0, 0.072),
        0 42px 134px rgba(0, 0, 0, 0.1);

}

body {
    font-family: 'Noto Sans', sans-serif;

    background-color: var(--COLOR-5);
    color: var(--COLOR-2);

    overflow-y: scroll;
}

#main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (min-width: 980px) {
    #content {
        max-width: 800px;
    }

    #name-title {
        font-size: 4rem;
    }
}

@media only screen and (min-width: 600px) and (max-width: 980px) {
    #content {
        max-width: 590px;
    }

    #name-title {
        font-size: 2.8rem;
    }
}

@media only screen and (max-width: 600px) {
    #content {
        width: 90vw;
    }

    #name-title {
        font-size: 1.7rem;
    }
}

#content {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    flex-grow: 3;
}

#background {
    min-height: 400px;
}

.career-era {
    background-color: var(--COLOR-4);
    padding: 5px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: var(--DEFAULT-SHADOW);
    font-size: 1.1rem;
}

.career-era p {
    margin: 5px;
}

.era-time {
    background-color: var(--COLOR-3);
    margin: 2px;
    padding: 5px;
    width: fit-content;
    color: var(--COLOR-1);
}

#name-title,
#subtitle,
.button {
    font-family: 'Prata', serif;
}