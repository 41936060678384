/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

#loader {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin-top: 40px;
}

h3 {
    text-align: center;
}

#contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    margin-top: 10px;
    width: 200px;
    max-width: 500px;
    padding: 5px;
    font-family: 'Noto Sans', sans-serif;
}

input.empty-field {
    border: 3px solid red;
}

textarea {
    font-family: 'Noto Sans', sans-serif;
    min-width: 250px;
    margin-top: 10px;
}

textarea.empty-field {
    border: 3px solid red;
}

button[type=submit],
.reset-form-btn {
    border: none;
    transition: all 0.5s ease-in-out;
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 250px;
    height: 40px;
}

.enabled,
.reset-form-btn {
    cursor: pointer;
    background-color: var(--COLOR-3);
}

.disabled {
    background-color: var(--COLOR-6);
}

.submit-loading, .submit-ok {
    height: 90px !important;
    width: 90px !important;
    border-radius: 100%;
}

.submit-ok {
    background-color: green;
}